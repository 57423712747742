body .cke_dialog {
  z-index: 9999999999 !important;
}
body .cke_dialog_background_cover {
  z-index: 9999999998 !important;
}
body .trad_cms {
  font-family: inherit;
  font: inherit;
  color: inherit;
  text-align: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  letter-spacing: inherit;
  font-weight: inherit;
  vertical-align: inherit;
  list-style: none;
  border: 0;
}
body .trad_cms:not(.type_bg_title) {
  background: transparent;
}
body .trad_cms.highlighted {
  border: 1px solid #FFA726;
  color: #FFF;
  outline: 1px solid #FFA726;
  background-color: #FFA726;
  background-size: fill;
}
body .trad_cms.trad_cms_editable {
  cursor: alias;
}
body .trad_cms.trad_cms_editable:hover {
  color: #FFA726;
}
body .clearfix:before {
  clear: both;
}
body #open_edit_trad {
  right: 5%;
  top: 90%;
  background-color: #2196F3;
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
  position: fixed;
  border-radius: 40px;
  cursor: pointer;
  height: 50px;
  width: 50px;
  z-index: 999999999;
}
body #open_edit_trad.open_edit {
  background-image: url('/assets/images/trad/edit.png');
}
body #open_edit_trad.close_edit {
  background-image: url('/assets/images/trad/forbidden.png');
}
body #open_edit_trad.expand_pop {
  background-image: url('/assets/images/trad/expand.png');
}
body #trad_fromcms,
body #edit_fascia_cms {
  display: none;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
}
body #trad_fromcms.show,
body #edit_fascia_cms.show {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999999;
  overflow-y: scroll;
  left: 0;
  top: 0;
}
body #trad_fromcms.show .cont_trad,
body #edit_fascia_cms.show .cont_trad {
  display: table;
  width: 100%;
  height: 100%;
}
body #trad_fromcms.show .cont_trad .pop,
body #edit_fascia_cms.show .cont_trad .pop {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  margin: auto;
  /*opacity:1;
                    position:absolute;	
                    top: 50%;
                    left: 50%;*/
  /*min-width:600px;  	
                    min-height:400px;  	*/
  /*-webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);*/
}
body #trad_fromcms.show .cont_trad .pop #load_din,
body #edit_fascia_cms.show .cont_trad .pop #load_din {
  margin: auto;
  width: 100%;
  max-width: 940px;
  position: relative;
  font-size: 16px;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din,
  body #edit_fascia_cms.show .cont_trad .pop #load_din {
    padding: 20px;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content {
  margin: 15px 0;
  background-color: #FFF;
  width: 100%;
  float: left;
  position: relative;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content.msg_mostra,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content.msg_mostra {
  height: 400px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content.msg_mostra #content_load,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content.msg_mostra #content_load {
  display: none;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content #content_load,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content #content_load {
    float: left;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaTrad,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaTrad,
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaLoad,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaLoad {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaTrad.displayOn,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaTrad.displayOn,
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaLoad.displayOn,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaLoad.displayOn {
  z-index: 25000;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaTrad .message,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaTrad .message,
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaLoad .message,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaLoad .message {
  width: 100%;
  height: 100%;
  position: relative;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaTrad .message p,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaTrad .message p,
body #trad_fromcms.show .cont_trad .pop #load_din .content .velinaLoad .message p,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .velinaLoad .message p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  text-align: center;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header {
  float: left;
  width: 100%;
  padding-left: 5%;
  margin-top: 30px;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header {
    padding: 0 5%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header ul,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header ul {
  text-align: center;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header ul li,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header ul li {
  float: left;
  text-align: center;
  line-height: 30px;
  width: auto;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header ul li a,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header ul li a {
  background-color: #BBB;
  padding: 0 10px;
  text-align: center;
  color: #FFF;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 10px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header ul li a.active,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header ul li a.active {
  text-decoration: underline;
  -webkit-appearance: none;
  appearance: none;
  background-color: #2196F3;
  cursor: default;
  position: relative;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_header ul li a.active::before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_header ul li a.active::before {
  position: absolute;
  right: 0;
  top: 80%;
  content: " ";
  background-image: url('/assets/images/trad/caret-down.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 25px;
  height: 25px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content {
  float: left;
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab {
  padding: 0 2.5%;
  display: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form {
  float: left;
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form textarea.hidden_text,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form textarea.hidden_text {
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .cke:hover,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .cke:hover,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .cke:focus,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .cke:focus {
  border: 2px solid #26a69a;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in {
  float: left;
  width: 90%;
  margin: 20px 3%;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 20px;
  text-align: left;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info {
  float: left;
  width: 48%;
  margin: 0 1%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info {
    width: 98%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info .info,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .label_info .info {
  margin: 10px 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .posizione,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .posizione {
  margin: 15px 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore:before {
  content: "...";
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore:before {
  content: "-";
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore {
  font-size: 20px;
  cursor: pointer;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .presente,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .presente,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore {
  margin: 5px 15px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .presente.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .presente.hidden,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .separatore.hidden,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .presente_in .successore.hidden {
  display: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list {
  float: left;
  width: 90%;
  margin: 0 5%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input:first-child label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input:first-child label,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input:last-child label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input:last-child label {
  padding: 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .inputs .box_input label {
  padding: 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #E0E0E0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.tri .box_input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.tri .box_input {
  width: 31.3%;
  margin: 0;
  margin-right: 2%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.tri .box_input,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.tri .box_input {
    margin: 0 5%;
    width: 90%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.single .box_input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.single .box_input {
  width: 100%;
  margin: 0;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header {
    display: none;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header.noBorder,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header.noBorder {
  border: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header .inputs .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header .inputs .box_input label,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header .inputs.single .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element.header .inputs.single .box_input label {
  width: 100%;
  padding: 0;
  text-align: left;
  font-weight: bolder;
  display: block;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs {
  width: 75%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs .box_input label {
  display: none;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs .box_input label,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs .box_input label {
    display: block;
    margin: 10px 0;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.single,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .data_list .data_element .inputs.single {
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs {
  margin-top: 25px;
  float: left;
  width: 100%;
}
@media (min-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.single .box_input,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.single .box_input {
    width: 95%;
    margin: 7px 2.5%;
  }
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.tri .box_input,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.tri .box_input {
    width: 29%;
    margin: 0 2%;
  }
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.tri .box_input.w100,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs.tri .box_input.w100 {
    width: 95%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input {
  font-size: 14px;
  float: left;
  width: 45%;
  text-align: left;
  margin: 7px 2.5%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w80,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w80 {
  width: 75%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w80,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w80 {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w20,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w20 {
  width: 15%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w20,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w20 {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w100,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w100 {
  width: 95%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w100,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.w100 {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.textarea label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.textarea label {
  font-size: 16px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont {
  margin-top: 20px;
  float: left;
  width: 100%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont {
    float: left;
    width: 95%;
    padding: 2.5%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont textarea,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .textarea_cont textarea {
  height: 100px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.error label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.error label {
  color: #D32F2F;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input p,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input p {
  padding: 5px 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input p.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input p.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file {
  width: auto;
  margin: 0 5%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file {
    width: 90%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file.right,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file.right {
  float: right;
  text-align: right;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob {
    float: left;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load {
  background-color: #2196F3;
  padding: 10px;
  width: 150px;
  text-align: center;
  color: #FFF;
  border-radius: 10px;
  cursor: pointer;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load#load_elem,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load#load_elem {
  display: none;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file .center_mob .button_load {
    float: none;
    margin: auto;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file input[type='file'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input.file input[type='file'] {
  visibility: hidden;
  display: none;
  height: 0px;
  width: 0px;
  opacity: 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input label {
  float: left;
  padding: 5px;
  width: 100%;
  display: inline;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input label,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input label {
    float: left;
    width: 90%;
    display: block;
    padding: 0 5%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont {
  float: left;
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont {
  position: relative;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'],
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label {
  font-size: 14px;
  padding-left: 25px;
  margin-top: 30px;
  padding-bottom: 6px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:focus,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:focus,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:focus,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:focus,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:hover,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:hover,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:hover,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:hover {
  color: #26a69a;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:focus:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:focus:before,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:focus:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:focus:before,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:hover:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:hover:before,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:hover:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:hover:before {
  background-color: #26a69a;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label,
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label {
    margin-top: 10px;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:before,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:before {
  position: absolute;
  border: none;
  left: 0;
  top: 3px;
  background-position: bottom left;
  background-size: cover;
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/trad/checkbox_unchecked.png');
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:before,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:before,
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:before,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:before {
    top: 0px;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:after,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox'] + label:after,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:after,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox'] + label:after {
  content: " ";
  background: none;
  width: 0;
  height: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox']:checked + label:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox']:checked + label:before,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox']:checked + label:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox']:checked + label:before {
  background-image: url('/assets/images/trad/checkbox_checked.png');
  border: none;
  transform: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox']:checked + label:after,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.check_cont input[type='checkbox']:checked + label:after,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox']:checked + label:after,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.check_cont input[type='checkbox']:checked + label:after {
  content: " ";
  background: none;
  width: 0;
  height: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont.hidden,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont,
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont {
    float: left;
    width: 90%;
    padding: 0 5%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont {
  margin-top: 12px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input {
  font-size: 14px;
  height: auto;
  padding: 5px;
  margin: 0;
  border: 1px solid #ccc;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input:focus,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input:focus,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input:hover,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input:hover {
  border-color: #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input[type='text'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .input_cont input[type='text'] {
  float: left;
  width: 95%;
  height: auto;
  padding: 5px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont {
  position: relative;
  padding-right: 0;
  padding-left: 5%;
  border: 1px solid #9e9e9e;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont select,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont select {
  font-size: 14px;
  padding-left: 5%;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  text-align: right;
  height: 40px;
  position: relative;
  border: none;
  outline: none;
  padding: 5px 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont select option,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont select option {
  text-align: right;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont:after,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .inputs .box_input .select_cont:after {
  content: " ";
  position: absolute;
  right: 10px;
  top: 30%;
  width: 20px;
  height: 20px;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/assets/images/trad/down-arrow.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom {
  float: left;
  width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert {
  float: left;
  width: 75%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert {
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input {
  margin: 0;
  width: 31.3%;
  margin-right: 2%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input.w100,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input.w100 {
  width: 95%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input {
    width: 95%;
    margin: 20px 2.5%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input label {
  display: none;
  padding: 0 5%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input label,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input label {
    display: block;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont,
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .select_cont,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .select_cont {
  width: 90%;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .select_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .select_cont,
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont {
    margin: 10px 0;
    margin-left: 5%;
  }
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom.data_container .data_insert .box_input .input_cont {
    padding: 0;
    width: 90%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image {
  float: left;
  width: 100%;
  margin-top: 20px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image label,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image label {
  margin: 10px 0;
  float: left;
  width: 100%;
  text-align: center;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image .cont_image,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image .cont_image {
  padding: 0 5%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image .cont_image img,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_image .cont_image img {
  max-width: 100%;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input {
  /*label{
                                                    @media (max-width:768px){
                                                        float:left;
                                                        width:100%;
                                                        text-align:left;
                                                        padding:0 5%;
                                                    }    
                                                    
                                                    text-align:center;
                                                    font-size:20px;
                                                    line-height:20px;
                                                }*/
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit {
  float: right;
  text-align: right;
  padding-right: 15px;
  margin: 25px 0;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit input[type='submit'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit input[type='submit'],
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit input[type='button'],
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .box_input.submit input[type='button'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #2196F3;
  padding: 10px;
  width: 150px;
  text-align: center;
  color: #FFF;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: none;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions {
  float: left;
  width: 25%;
  padding: 0 5%;
  text-align: center;
  margin: 30px 0;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions,
  body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions {
    margin: 15px 0;
    width: 100%;
  }
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img {
  float: right;
  text-align: center;
  width: 32px;
  height: 32px;
  margin: auto;
  position: relative;
  cursor: pointer;
  margin: 0 2px;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: " ";
  background-size: cover;
  background-repeat: no-repeat;
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.add:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.add:before {
  background-image: url('/assets/images/trad/add.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.edit:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.edit:before {
  background-color: #2196F3;
  border-radius: 20px;
  background-size: 20px;
  background-position: center;
  background-image: url('/assets/images/trad/edit.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.save:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.save:before {
  background-color: #4CAF50;
  border-radius: 20px;
  background-size: 20px;
  background-position: center;
  background-image: url('/assets/images/trad/save.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.cancel:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.cancel:before {
  background-color: #2196F3;
  border-radius: 20px;
  background-size: 20px;
  background-position: center;
  background-image: url('/assets/images/trad/prohibition.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.remove:before,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.remove:before {
  background-image: url('/assets/images/trad/cancel-button.png');
}
body #trad_fromcms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.hidden,
body #edit_fascia_cms.show .cont_trad .pop #load_din .content .tabs_content .tab form .bottom .actions .img.button.hidden {
  display: none;
}
body #trad_fromcms.show .cont_trad .pop .close,
body #edit_fascia_cms.show .cont_trad .pop .close {
  position: absolute;
  right: -15px;
  top: -10px;
  padding: 10px;
  cursor: pointer;
  background-color: #2196F3;
  text-align: center;
  color: #FFF;
  z-index: 27000;
  border-radius: 5px;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop .close,
  body #edit_fascia_cms.show .cont_trad .pop .close {
    right: -15px;
    top: -15px;
  }
}
body #trad_fromcms.show .cont_trad .pop .minimize,
body #edit_fascia_cms.show .cont_trad .pop .minimize {
  position: absolute;
  right: 20px;
  top: -10px;
  padding: 10px;
  cursor: pointer;
  background-color: #FFA726;
  text-align: center;
  color: #FFF;
  border-radius: 5px;
}
@media (max-width: 768px) {
  body #trad_fromcms.show .cont_trad .pop .minimize,
  body #edit_fascia_cms.show .cont_trad .pop .minimize {
    right: 20px;
    top: -15px;
  }
}
body #trad_fromcms.show .cont_trad .pop .minimize:before,
body #edit_fascia_cms.show .cont_trad .pop .minimize:before {
  content: "-";
}
