body{

    .cke_dialog{
        z-index:9999999999 !important;
    }

    .cke_dialog_background_cover{
        z-index:9999999998 !important;
    }

	.trad_cms{

		font-family: inherit;
        font: inherit;
        color: inherit;
        &:not(.type_bg_title){
            background: transparent;
        }
        text-align: inherit; 
        line-height: inherit;
        padding:0;
        margin:0;
        letter-spacing: inherit;
        font-weight: inherit;
        vertical-align: inherit;
        list-style: none;
        border: 0;
        
        &.highlighted{

            border: 1px solid #FFA726;
            color: #FFF;
            outline: 1px solid #FFA726;
            background-color:#FFA726;
            background-size:fill;
        }

        &.trad_cms_editable{
		      cursor:alias;
            
            &:hover{
                
                color:#FFA726;
            }
        }
        
	} 
    
    .clearfix{
        &:before{
            clear:both;
        }
    }

    #open_edit_trad{
        right: 5%;
        top: 90%;        
        background-color: #2196F3;
        background-position: center center;
        background-size: 50%;
        background-repeat: no-repeat;
        position: fixed;
        border-radius: 40px;
        cursor: pointer;
        height: 50px;
        width: 50px;      
        z-index:999999999;
        
        &.open_edit{
            
            background-image: url('/assets/images/trad/edit.png');
        }
        
        &.close_edit{
            
            background-image: url('/assets/images/trad/forbidden.png');
        }
        
        &.expand_pop{
            
            background-image: url('/assets/images/trad/expand.png');
        }
    }
    
    #trad_fromcms,#edit_fascia_cms{
        display:none;
        font-family:Verdana, Geneva, sans-serif;
        font-size:14px;

        &.show{
            
            
            
            display:block;
            position:fixed;
            width:100%;
            height:100%;
            background-color:rgba(0,0,0,0.7);	
            z-index:999999999;
            overflow-y:scroll;
            left:0;
            top:0;
            
            .cont_trad{
                display:table;
                width:100%;
                height:100%;
                
                .pop{
                    
                    display:table-cell;
                    vertical-align: middle;
                    text-align:center;
                    width:100%;
                    
                    margin:auto;
                    /*opacity:1;
                    position:absolute;	
                    top: 50%;
                    left: 50%;*/
                    /*min-width:600px;  	
                    min-height:400px;  	*/
                    /*-webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);*/
                    

                    #load_din{

                        margin:auto;                                                                    
                        width:100%;
                        max-width:940px;
                        position:relative;
                        font-size:16px;


                        @media (max-width:768px){
                            padding:20px;    
                        }
                        
                        
                        .content{
                            
                            margin:15px 0;
                            
                            &.msg_mostra{

                                height:400px;

                                #content_load{

                                    display:none;
                                }
                            }

                            @media (max-width:768px){
                                
                                #content_load{
                                                                        
                                    float:left;
                                }
                            }

                            background-color:#FFF;
                            width:100%;
                            float:left;
                            position:relative;

                            .velinaTrad,.velinaLoad{

                                z-index:-1;
                                position:absolute;
                                left:0;
                                top:0;
                                width:100%;
                                height:100%;
                                background-color:rgba(255,255,255,1);

                                &.displayOn{

                                    z-index:25000;
                                }

                                .message{

                                    width:100%;
                                    height:100%;
                                    position:relative;

                                    p{
                                        position:absolute;
                                        left:50%;
                                        top:50%;
                                        transform:translate(-50%,-50%);
                                        min-width:300px;
                                        text-align:center;
                                    }
                                }
                            }

                            .tabs_header{
                                
                                float:left;
                                width:100%;
                                
                                @media (max-width:768px){
                                    
                                    padding:0 5%;
                                }
                                
                                padding-left:5%;
                                margin-top:30px;
                                
                                ul{
                                    
                                    text-align:center;

                                    li{

                                        float:left;
                                        
                                        text-align:center;
                                        line-height:30px;
                                        width:auto;                                                                            
                                        
                                        
                                        a{
                                            background-color:#BBB;
                                            padding:0 10px;    
                                            text-align: center;
                                            color: #FFF;    
                                            cursor: pointer;
                                            outline: none;
                                            border: none;
                                            padding: 10px; 
                                            
                                            &.active{
                                                text-decoration: underline;                                                                                           
                                                -webkit-appearance: none;
                                                appearance: none;
                                                background-color: #2196F3;                                                     
                                                cursor:default;
                                                position:relative;
                                                
                                                &::before{
                                                    position: absolute;
                                                    right: 0;
                                                    top: 80%;
                                                    content: " ";
                                                    background-image: url('/assets/images/trad/caret-down.png');
                                                    background-repeat: no-repeat;                                                    
                                                    background-size: cover;
                                                    background-position: top;
                                                    width: 25px;
                                                    height: 25px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }



                            .tabs_content{

                                float:left;
                                width:100%;

                                .tab{
                                    
                                    padding:0 2.5%;                              
                                    display:none;
                                    
                                    form{                                
                                        
                                        textarea.hidden_text{
                                            display:none;
                                            height:0;
                                            width:0;
                                            visibility:hidden;
                                            opacity:0;
                                        }

                                        .cke:hover,.cke:focus{
                                            border:2px solid #26a69a;
                                            // box-shadow:1px 1px 1px 1px #26a69a;
                                        }
                                        
                                        float:left;
                                        width:100%;
                                        
                                        .presente_in{
                                            float:left;
                                            width:90%;                                            
                                            margin:20px 3%;
                                            border:1px solid #ccc;
                                            font-size:14px;
                                            padding:20px;
                                            text-align:left;

                                            .label_info{
                                                float:left;
                                                width:48%;
                                                margin:0 1%;

                                                @media (max-width:768px){

                                                    width:98%;

                                                }

                                                .info{
                                                    margin:10px 0;
                                                }
                                            }

                                            .posizione{                                                
                                                margin:15px 0;
                                            }

                                            .separatore:before{
                                                content:"...";                                                
                                            }

                                            .successore:before{
                                                content:"-";
                                            }

                                            .separatore,.successore{
                                                font-size:20px;
                                                cursor:pointer;
                                            }

                                            .presente,.separatore,.successore{

                                                margin:5px 15px;

                                                &.hidden{

                                                    display:none;
                                                }
                                            }
                                        }

                                        .data_list{
                                            float:left;
                                            width:90%;
                                            margin:0 5%;

                                            .inputs{
                                                .box_input{
                                                    &:first-child,&:last-child{

                                                        label{
                                                            padding:0;
                                                        }
                                                    }
                                                    label{
                                                        padding:0;
                                                    }                                                
                                                }    
                                            }
                                            
                                            .data_element{

                                                float:left;
                                                width:100%;                                                
                                                padding-bottom:10px;
                                                border-bottom:1px solid #E0E0E0;
                                                
                                                .inputs.tri{

                                                    .box_input{
                                                        width:31.3%; 
                                                        margin:0;
                                                        margin-right:2%;   
                                                        @media(max-width:768px){
                                                            margin:0 5%;
                                                            width:90%;
                                                        }
                                                    }
                                                    
                                                }

                                                .inputs.single{

                                                    .box_input{
                                                        width:100%;    
                                                        margin:0;
                                                    }
                                                    
                                                }

                                                &.header{

                                                    @media(max-width:768px){
                                                        display:none;
                                                    }

                                                    &.noBorder{
                                                        border:none;
                                                    }
                                                    

                                                    .inputs,.inputs.single{

                                                        .box_input{
                                                            
                                                            label{
                                                                width:100%;
                                                                padding:0;
                                                                text-align:left;
                                                                font-weight:bolder;
                                                                display:block;
                                                            }    
                                                        }
                                                    }     
                                                    
                                                }
                                                .inputs{
                                                    width:75%;
                                                    @media(max-width:768px){
                                                        width:100%;
                                                    }

                                                    .box_input{

                                                        label{
                                                            display:none;

                                                            @media (max-width:768px){

                                                                display:block;
                                                                margin:10px 0;
                                                            }
                                                        }

                                                        
                                                    }
                                                }

                                                .inputs.single{
                                                    width:100%;
                                                }

                                            }
                                        }

                                        .inputs{
                                            
                                            margin-top:25px;                                            
                                            float:left;
                                            width:100%;
                                            
                                            @media (min-width:768px){
                                                    
                                                &.single{

                                                    .box_input{                                                        

                                                        width:95%;
                                                        margin:7px 2.5%;
                                                    }
                                                }

                                                &.tri{
                                                    .box_input{

                                                        &.w100{
                                                            width:95%;
                                                        }
                                                        width:29%;
                                                        margin:0 2%;
                                                    }
                                                }
                                            }
                                            
                                            .box_input{
                                                
                                                &.w80{
                                                    width:75%;
                                                    @media (max-width:768px){
                                                        width:100%;
                                                    }
                                                }

                                                &.w20{
                                                    width:15%;
                                                    @media (max-width:768px){
                                                        width:100%;
                                                    }
                                                }

                                                &.w100{
                                                    width:95%;
                                                    @media (max-width:768px){
                                                        width:100%;
                                                    }
                                                }

                                                font-size:14px;
                                                
                                                &.textarea{
                                                    label{
                                                        // font-weight:bold;
                                                        font-size:16px;
                                                    }
                                                }
                                                .textarea_cont{
                                                    
                                                    margin-top:20px;

                                                    @media (max-width:768px){
                                                        float:left;
                                                        width:95%;
                                                        padding:2.5%;
                                                    }
                                                    
                                                    float:left;
                                                    width:100%;                                                    

                                                    textarea{

                                                        height:100px;
                                                        border:1px solid #ccc;
                                                        padding:5px;
                                                        width:100%;
                                                    }
                                                }

                                                &.error{
                                                    label{
                                                        color:#D32F2F;
                                                    }
                                                }
                                                
                                                p{
                                                    padding:5px 0;
                                                    &.hidden{
                                                        display:none;
                                                        visibility:hidden;
                                                        opacity:0;
                                                    }
                                                }

                                                @media (max-width:768px){
                                                    width:100%;
                                                }
                                                
                                                &.file{
                                                    
                                                    width:auto;
                                                    margin:0 5%;
                                                    
                                                    @media (max-width:768px){
                                                        width:90%;
                                                    }
                                                    
                                                    &.right{
                                                        float:right;
                                                        text-align:right;
                                                        //margin-right:50px;
                                                    }
                                                    
                                                    .center_mob{
                                                        
                                                        @media (max-width:768px){
                                                            float:left;
                                                            width:100%;
                                                            text-align:center;
                                                            margin:5px 0;
                                                        }
                                                        
                                                        .button_load{
                                                        
                                                            &#load_elem{
                                                                display:none;
                                                            }
                                                            
                                                            @media (max-width:768px){

                                                                float:none;
                                                                margin:auto;
                                                            }

                                                            background-color: #2196F3;
                                                            padding: 10px;                                                        
                                                            width: 150px;
                                                            text-align: center;
                                                            color: #FFF;
                                                            border-radius: 10px;
                                                            cursor:pointer;                                                            
                                                        }
                                                    }
                                                    
                                                    
                                                    input[type='file']{
                                                        visibility:hidden;
                                                        display:none;
                                                        height:0px;
                                                        width:0px;
                                                        opacity:0;
                                                    }
                                                }
                                                

                                                float:left;                                                 
                                                width:45%;                                                
                                                text-align:left;
                                                margin:7px 2.5%;
                                                

                                                
                                                label{
                                                    
                                                    @media (max-width:768px){
                                                        float:left;
                                                        width:90%;
                                                        display:block;
                                                        padding:0 5%;
                                                    }
                                                    
                                                    float:left;
                                                    
                                                    padding:5px;                                               
                                                    width:100%;
                                                    display:inline;                                                    
                                                    text-transform: capitalize;
                                                }

                                                .input_cont,.select_cont{

                                                    &.check_cont{

                                                        position:relative;
                                                        input[type='checkbox']{
                                                            width:0;
                                                            height:0;
                                                            opacity:0;
                                                            display:none;                                                            
                                                        }

                                                        input[type='checkbox']+label{

                                                            font-size:14px;
                                                            padding-left:25px;
                                                            margin-top:30px;
                                                            padding-bottom:6px;

                                                            &:focus,&:hover{
                                                                color:#26a69a;

                                                                &:before{
                                                                    background-color:#26a69a;
                                                                }
                                                            }
                                                            @media (max-width:768px){
                                                                margin-top:10px;
                                                            }


                                                            &:before{
                                                                position:absolute;
                                                                border:none;
                                                                left:0;
                                                                top:3px;
                                                                @media (max-width:768px){
                                                                    top:0px;
                                                                }
                                                                
                                                                background-position:bottom left;
                                                                background-size:cover;
                                                                width:20px;
                                                                height:20px;
                                                                background-image:url('/assets/images/trad/checkbox_unchecked.png');
                                                            }                                                                                                                        
                                                            &:after{
                                                                content:" ";
                                                                background:none;
                                                                width:0;
                                                                height:0;
                                                                display:none;
                                                                opacity:0;
                                                                visibility:hidden;   
                                                            }
                                                        }

                                                        input[type='checkbox']:checked+label{
                                                            &:before{
                                                                background-image:url('/assets/images/trad/checkbox_checked.png');
                                                                border:none;
                                                                transform:none;
                                                            }
                                                            &:after{
                                                                content:" ";
                                                                background:none;
                                                                width:0;
                                                                height:0;
                                                                display:none;
                                                                opacity:0;
                                                                visibility:hidden;   
                                                            }
                                                        }
                                                    }
                                                    &.hidden{
                                                        display:none;
                                                        visibility:hidden;
                                                        opacity:0;
                                                    }
                                                    @media (max-width:768px){
                                                        float:left;
                                                        width:90%;
                                                        padding:0 5%;
                                                    }
                                                    
                                                    float:left;
                                                    width:100%;                                                                                                                                                        
                                                }

                                                .input_cont{

                                                    input{

                                                        font-size:14px;
                                                        height:auto;
                                                        padding:5px;
                                                        margin:0;          
                                                        border:1px solid #ccc;

                                                        &:focus,&:hover{
                                                            border-color:#26a69a;
                                                            box-shadow:0 1px 0 0 #26a69a;
                                                        }                                             
                                                    }
                                                    
                                                    margin-top:12px;

                                                    input[type='text']{
                                                        
                                                        float:left;
                                                        //@media (max-width:768px){                                                            
                                                            width:95%;
                                                        //}                                                        
                                                        height:auto;
                                                        //width:auto;
                                                        padding:5px;
                                                    }
                                                }

                                                .select_cont{                                                    

                                                    position:relative;
                                                    padding-right:0;
                                                    padding-left:5%;
                                                    border:1px solid #9e9e9e;                                                                                                     
                                                    select{

                                                        font-size:14px;

                                                        padding-left:5%;
                                                        display:block;
                                                        -webkit-appearance:none;
                                                        appearance:none;
                                                        -moz-appearance:none;                                                        
                                                        text-align:right;                                                                                                                                                                                                                        
                                                        height:40px;
                                                        position:relative;                                                        
                                                        border:none;
                                                        outline:none;
                                                        padding:5px 0;

                                                        option{
                                                            text-align:right;
                                                        }
                                                    }

                                                    &:after{
                                                        content:" ";
                                                        position:absolute;
                                                        right:10px;
                                                        top:30%;
                                                        width:20px;
                                                        height:20px;
                                                        background-position: center right;
                                                        background-repeat: no-repeat;
                                                        background-size: cover;
                                                        background-image:url('/assets/images/trad/down-arrow.png');
                                                    }
                                                }

                                            }
                                        }

                                        .bottom{

                                            float:left;
                                            width:100%;
                                            
                                            &.data_container{

                                                .data_insert{
                                                    float:left;
                                                    width:75%;

                                                    @media (max-width:768px){

                                                        width:100%;
                                                    }

                                                    .box_input{
                                                        
                                                        margin:0;
                                                        width:31.3%;
                                                        margin-right:2%;

                                                        &.w100{
                                                            width:95%;
                                                        }

                                                        @media (max-width:768px){

                                                            width:95%;
                                                            margin:20px 2.5%;
                                                        }

                                                        label{                                                            
                                                            display:none;
                                                            padding:0 5%;
                                                            @media(max-width:768px){
                                                                display:block;
                                                            }
                                                        }

                                                        .input_cont,.select_cont{   

                                                            width:90%;
                                                            
                                                        }

                                                        @media(max-width:768px){
                                                            .select_cont, .input_cont{
                                                                margin:10px 0;
                                                                margin-left:5%;
                                                            }

                                                            .input_cont{

                                                                padding:0;
                                                                width:90%;                                                                
                                                            }
                                                        }

                                                    }
                                                }

                                            }

                                            .box_image{
                                                
                                                float:left;
                                                width:100%;
                                                margin-top:20px;
                                                
                                                label{
                                                    
                                                    margin:10px 0;
                                                    float: left;
                                                    width: 100%;
                                                    text-align: center;
                                                }
                                                
                                                .cont_image{
                                                    //padding:0 50px;
                                                    //@media (max-width:768px){                                                            
                                                        padding:0 5%;
                                                    //}
                                                    
                                                    img{
                                                        
                                                        max-width:100%;
                                                    }
                                                }
                                            }
                                            .box_input{

                                                
                                                /*label{
                                                    @media (max-width:768px){
                                                        float:left;
                                                        width:100%;
                                                        text-align:left;
                                                        padding:0 5%;
                                                    }    
                                                    
                                                    text-align:center;
                                                    font-size:20px;
                                                    line-height:20px;
                                                }*/

                                                
                                                
                                                &.submit{
                                                    
                                                    float:right;
                                                    text-align:right;
                                                    padding-right:15px;
                                                    
                                                    margin:25px 0;
                                                    
                                                    input[type='submit'],input[type='button']{
                                                        
                                                        -webkit-appearance:none;
                                                        appearance:none;
                                                        background-color: #2196F3;
                                                        padding: 10px;                                                        
                                                        width: 150px;
                                                        text-align: center;
                                                        color: #FFF;
                                                        border-radius: 10px;
                                                        cursor:pointer;
                                                        outline:none;
                                                        border:none;
                                                    }
                                                }
                                            }

                                            .actions{
                                                float:left;
                                                width:25%;
                                                padding:0 5%;
                                                text-align:center;
                                                margin:30px 0;

                                                @media (max-width:768px){
                                                    margin:15px 0;
                                                    width:100%;
                                                }

                                                .img{
                                                    float:right;
                                                    text-align:center;
                                                    width:32px;
                                                    height:32px;
                                                    margin:auto;
                                                    position:relative;
                                                    cursor:pointer;
                                                    margin:0 2px;

                                                    &.button{
                                                        &:before{
                                                            position:absolute;
                                                            left:0;
                                                            top:0;
                                                            width:100%;
                                                            height:100%;
                                                            content:" ";                                                                                                                                                                                    
                                                            background-size:cover;
                                                            background-repeat:no-repeat;
                                                        }                                                    
                                                        &.add{
                                                            &:before{
                                                                background-image:url('/assets/images/trad/add.png');
                                                            }
                                                        }

                                                        &.edit{
                                                            &:before{
                                                                background-color:#2196F3;
                                                                border-radius:20px;
                                                                background-size:20px;
                                                                background-position:center;
                                                                background-image:url('/assets/images/trad/edit.png');
                                                            }
                                                        }

                                                        &.save{
                                                            &:before{
                                                                background-color:#4CAF50;
                                                                border-radius:20px;
                                                                background-size:20px;
                                                                background-position:center;
                                                                background-image:url('/assets/images/trad/save.png');   
                                                            }
                                                        }

                                                        &.cancel{
                                                            &:before{
                                                                background-color:#2196F3;
                                                                border-radius:20px;
                                                                background-size:20px;
                                                                background-position:center;
                                                                background-image:url('/assets/images/trad/prohibition.png');
                                                            }
                                                        }

                                                        &.remove{
                                                            &:before{
                                                                background-image:url('/assets/images/trad/cancel-button.png');
                                                            }
                                                        }

                                                        &.hidden{
                                                            display:none;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        
                                    }
                                }
                            }
                        }
                        
                    }

                    .close{
                        
                        @media (max-width:768px){
                            right:-15px;
                            top:-15px;
                        }
                        position: absolute;
                        right: -15px;
                        top: -10px;
                        padding: 10px;
                        cursor: pointer;
                        background-color: #2196F3;                                                        
                        text-align: center;
                        color: #FFF;
                        z-index:27000;
                        border-radius: 5px;
                    }
                    
                    .minimize{
                        @media (max-width:768px){
                            right:20px;
                            top:-15px;
                        }
                        position: absolute;
                        right: 20px;
                        top: -10px;
                        padding: 10px;
                        cursor: pointer;
                        background-color: #FFA726;                                                        
                        text-align: center;
                        color: #FFF;
                        border-radius: 5px;
                        
                        &:before{
                            content:"-"
                        }
                    }
                } 
            }
                                   
        }                
    }
}